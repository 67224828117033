import { createBrowserRouter } from "react-router-dom";
import {
  About,
  Article,
  Bureau,
  Careers,
  Collective,
  CompanyProfile,
  Cult,
  Cultivation,
  CultivatorsHome,
  CultivatorsLayout,
  Culture,
  DefaultLayout,
  ErrorPage,
  Event,
  EventSeries,
  Home,
  PersonProfile,
  PodcastEpisode,
  PodcastSeries,
  ProgramPage,
  Register,
} from "./dynamicImporter";

const router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/careers",
        element: <Careers />,
      },
      {
        path: "/join",
        element: <Cult />,
      },
    ],
  },
  {
    path: "/cult",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/cult",
        element: <Cult />,
      },
      {
        path: "/cult/:department",
        element: <Cult />,
      },
    ],
  },
  {
    path: "/collective",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/collective",
        element: <Collective />,
      },
      {
        path: "/collective/:companyType",
        element: <Collective />,
      },
    ],
  },
  {
    path: "/cultivation",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/cultivation",
        element: <Cultivation />,
      },
      {
        path: "/cultivation/:cultivationTab",
        element: <Cultivation />,
      },
      {
        path: "/cultivation/program/:programID",
        element: <ProgramPage />,
      },
    ],
  },
  {
    path: "/culture",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/culture",
        element: <Culture />,
      },
      {
        path: "/culture/:cultureTab",
        element: <Culture />,
      },
      {
        path: "/culture/article/:articleID",
        element: <Article />,
      },
      {
        path: "/culture/event/:eventID",
        element: <Event />,
      },
      {
        path: "/culture/event-series/:eventSeriesID",
        element: <EventSeries />,
      },
      {
        path: "/culture/event-series/:eventSeriesID/:panels",
        element: <EventSeries />,
      },
      {
        path: "/culture/event/:eventID/gallery",
        element: <Event />,
      },
      {
        path: "/culture/news/:articleID",
        element: <Article />,
      },
      {
        path: "/culture/podcast/:podcastSeriesID",
        element: <PodcastSeries />,
      },
      {
        path: "/culture/podcast/episode/:podcastEpisodeID",
        element: <PodcastEpisode />,
      },
    ],
  },
  {
    path: "/organizations/:orgSlug",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/organizations/:orgSlug",
        element: <CompanyProfile />,
      },
    ],
  },
  {
    path: "/profile/:personID",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/profile/:personID",
        element: <PersonProfile />,
      },
    ],
  },
  {
    path: "/bureau",
    element: <DefaultLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/bureau",
        element: <Bureau />,
      },
    ],
  },
  {
    path: "/cultivators",
    element: <CultivatorsLayout />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/cultivators", element: <CultivatorsHome /> },
      { path: "/cultivators/register", element: <Register /> },
    ],
  },
  {
    path: "/404",
    element: <ErrorPage />,
  },
]);

export default router;
