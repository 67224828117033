//React lazy loading and Component Loader

import { lazy } from "react";
import Loader from "./Loader";

//Layout Components

export const DefaultLayout = Loader(lazy(() => import("../components/Layouts/DefaultLayout")));

export const CultivatorsLayout = Loader(
  lazy(() => import("../components/Layouts/CultivatorsLayout"))
);

//Main Pages Components

export const Home = Loader(lazy(() => import("../pages/Home")));
export const About = Loader(lazy(() => import("../pages/About")));
export const Cult = Loader(lazy(() => import("../pages/Cult")));
export const Collective = Loader(lazy(() => import("../pages/Collective")));
export const Cultivation = Loader(lazy(() => import("../pages/Cultivation")));
export const Culture = Loader(lazy(() => import("../pages/Culture")));
export const Careers = Loader(lazy(() => import("../pages/Careers")));
export const Bureau = Loader(lazy(() => import("../pages/Bureau/Bureau")));
export const CultivatorsHome = Loader(lazy(() => import("../pages/Cultivators/CultivatorsHome")));
export const ErrorPage = Loader(lazy(() => import("../pages/ErrorPage")));

//Profile Pages

export const CompanyProfile = Loader(lazy(() => import("../pages/CompanyProfile")));
export const PersonProfile = Loader(lazy(() => import("../pages/PersonProfile")));

//Culture Sections

export const Article = Loader(lazy(() => import("../pages/Article")));
export const Event = Loader(lazy(() => import("../pages/Event")));
export const EventSeries = Loader(lazy(() => import("../pages/EventSeries")));
export const PodcastSeries = Loader(lazy(() => import("../pages/PodcastSeries")));
export const PodcastEpisode = Loader(lazy(() => import("../pages/PodcastEpisode")));

//Cultivators Sub-pages

export const Register = Loader(lazy(() => import("../pages/Cultivators/Register")));

//Cultivation Sections

export const ProgramPage = Loader(lazy(() => import("../pages/ProgramPage")));
